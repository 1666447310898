import React from 'react';

import styled from 'styled-components';
import theme from 'config/theme';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import { Button } from 'lib/Button';
import routes from 'config/routes';
import useRouter from 'hooks/useRouter';
import { featureFlagContainer, FeatureFlag } from 'containers/featureFlags';

import { SectionTitle } from './styles';

const { colors } = theme;

interface LandingInfoBlock {
	imageUrl: string;
	title: string;
	description: string;
}

export interface InfoBlocksSectionProps {
	title: string;
	items: LandingInfoBlock[];
	background?: string;
	isLandlord?: boolean;
}

const InfoBlocksSection = ({ items, title, background, isLandlord }: InfoBlocksSectionProps) => {
	const [goTo] = useRouter();

	return (
		<Container style={{ background: background || colors.white }}>
			<SectionTitle>{title}</SectionTitle>
			<ItemsContainer>
				{items.map(item => (
					<Item key={item.title}>
						<ItemImage src={item.imageUrl} />
						<ItemTitle>{item.title}</ItemTitle>
						<ItemText style={{ color: colors.black }}>{item.description}</ItemText>
					</Item>
				))}
			</ItemsContainer>
			{isLandlord ? (
				<Button
					isPink
					link={routes.create_listing}
					onClick={e => {
						e.stopPropagation();
						e.preventDefault();
						goTo(routes.create_listing);
					}}
				>
					+ Create your free listing
				</Button>
			) : (
				<Button isPink link={routes.digs_search}>
					Get started
				</Button>
			)}
		</Container>
	);
};

export default InfoBlocksSection;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: ${colors.darkTurquoiseFaded};
	padding: 48px 16px;
	padding-bottom: 24px;
`;

const ItemsContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 16px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		flex-direction: column;
	}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	max-width: 240px;
	margin: 0 24px;
`;

const ItemTitle = styled.div`
	font-weight: 600;
	text-align: center;
	margin-bottom: 16px;
`;

const ItemText = styled.div`
	color: ${colors.grey60};
	font-size: 14px;
	text-align: center;
	margin-bottom: 24px;
`;

const ItemImage = styled.img`
	height: 96px;
	margin-bottom: 16px;
	border-radius: 16px;
`;
