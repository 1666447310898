import ListingImageUrl from 'assets/images/homepage/listing.png';
import ConciergeImageUrl from 'assets/images/homepage/concierge.png';
import ChatImageUrl from 'assets/images/homepage/chat.png';
import PricingImageUrl from 'assets/images/homepage/pricing.png';
import PricingBlock1ImageUrl from 'assets/images/pricing/types.png';
import PricingBlock3ImageUrl from 'assets/images/pricing/fee.png';
import PricingBlock4ImageUrl from 'assets/images/pricing/chat.png';

import { InfoBlocksSectionProps } from './InfoBlocksSection';

export const howItWorksProps: InfoBlocksSectionProps = {
	title: 'How it works for landlords',
	items: [
		{
			imageUrl: ListingImageUrl,
			title: 'Create a free listing',
			description:
				'It’s completely free to list on DigsConnect. Click on ‘Create a listing’ and follow the prompts to create your property listing in 10 minutes.',
		},
		{
			imageUrl: ConciergeImageUrl,
			title: 'Receive enquiries and a first message from the tenant',
			description:
				'Once you have finished creating your property listing, the listing will go into moderation. Within 24 hours your new booking concierge will give you a call.',
		},
		{
			imageUrl: ChatImageUrl,
			title: 'Discuss leasing arrangements, share documents and arrange a viewing',
			description: `When applicants send you booking enquiries on DigsConnect, you can chat with them on the ‘Inbox’. You have 48 hours to reply to a booking request.`,
		},
		{
			imageUrl: PricingImageUrl,
			title: 'Sign the lease, and we’ll check in with the tenant to tie up any loose ends',
			description:
				'We charge a once-off 3.5% of the lease value. Your booking concierge will email you the invoice once the tenant has signed the lease.',
		},
	],
};

export const howItWorksTenantsProps: InfoBlocksSectionProps = {
	title: 'How it works for tenants',
	items: [
		{
			imageUrl: ChatImageUrl,
			title: 'Find the perfect digs',
			description:
				'Use DigsConnect to search for the perfect room to rent. We have hundreds of verified flats and partnerships.',
		},
		{
			imageUrl: ListingImageUrl,
			title: 'Create your account',
			description:
				'Creating an account is completely free on DigsConnect. Once a landlord contacts you, create your account to keep the conversation going.',
		},
		{
			imageUrl: ConciergeImageUrl,
			title: 'Chat to landlords',
			description:
				'Get directly in touch with verified landlords through DigsConnect’s inbox. Ask any questions, and iron out any requirements.',
		},
		{
			imageUrl: PricingImageUrl,
			title: 'Sign your lease',
			description: 'Once you’ve found that perfect Digs, sign your lease and have peace of mind.',
		},
	],
};

export const howPricingWorksProps: InfoBlocksSectionProps = {
	title: 'How Pricing Works',
	items: [
		{
			imageUrl: PricingBlock1ImageUrl,
			title: 'Listing is free',
			description:
				'Get all your properties listed on our secure platform. List as many properties as you’d like, at no extra cost.',
		},
		{
			imageUrl: PricingBlock3ImageUrl,
			title: 'Pay on success',
			description:
				'If you find a tenant on DigsConnect, your booking concierge will invoice you for the one-off success fee.',
		},
		{
			imageUrl: PricingBlock4ImageUrl,
			title: 'Invoice and fee',
			description:
				'This fee is 3.5% of the total lease value. This is the only cost for landlords, and there is no cost for tenants.',
		},
	],
};
